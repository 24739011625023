<template>
  <div id="build" class="grey lighten-2">

    <main-nav v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>
    
    <setup :user="user"></setup>



  </div>
</template>

<script>
// @ is an alias to /src
const player = () => import( "@/components/player.vue");
const setup = () => import( "@/components/setup.vue");
const mainNav = () => import("@/components/shared/nav.vue");
const mobileNav = () => import("@/components/shared/mobile-nav.vue");

import { mapGetters } from 'vuex'


export default {
  name: "build",
  metaInfo: {
    title: `Youtorial: Create your own!`
  },
  computed:{
    ...mapGetters({
      user:'GET_USER',
    }),
  },
  components: {
    player, mainNav, mobileNav,setup,
  },
  data(){
    return{
      viewSetup: true,
    }
  },
  methods: {

  },
  mounted(){
    this.$store.dispatch('toggleDrawer', false);
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#build{
  height: 100%;
  display: grid;
  // grid-template-rows:64px 1fr;
  // grid-template-columns: 1fr;
  // grid-template-areas:
  // "header"
  // "video";
  // header{
  //   grid-area:'header';
  // }
  // > .d-flex{
  //   grid-area:'video';
  // }
}

</style>
